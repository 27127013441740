import { LoadScript } from '@react-google-maps/api';
import React, { ReactNode } from 'react';

import { libraries, mapApiKey, mapIds } from '@/constants/maps/maps.constants';

// https://github.com/JustFly1984/react-google-maps-api/issues/159
class LoadScriptOnlyIfNeeded extends LoadScript {
  componentDidMount() {
    const cleaningUp = true;
    const isBrowser = typeof document !== 'undefined'; // require('@react-google-maps/api/src/utils/isbrowser')
    const isAlreadyLoaded =
      (window as any)?.google &&
      ((window as any)?.google as any)?.maps &&
      document.querySelector('body.first-hit-completed'); // AJAX page loading system is adding this class the first time the app is loaded

    if (!isAlreadyLoaded && isBrowser) {
      if ((window as any)?.google && !cleaningUp) {
        console.error('google api is already presented');
        return;
      }

      this.isCleaningUp().then(this.injectScript);
    }

    if (isAlreadyLoaded) {
      this.setState({ loaded: true });
    }
  }
}

type MapWrapperProps = {
  children: ReactNode;
};

export const MapWrapper: React.FC<MapWrapperProps> = ({ children }) => {
  return (
    <LoadScriptOnlyIfNeeded
      googleMapsApiKey={mapApiKey}
      mapIds={mapIds}
      libraries={libraries}
    >
      {children}
    </LoadScriptOnlyIfNeeded>
  );
};
